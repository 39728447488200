import { useState } from 'react';
import {
  getBillingPortalURL,
  type OrganisationCustomer,
} from '_api/orders/service';
import {
  Button,
  Input,
  ListBoxItem,
  Select,
  TextField,
  Spinner,
} from 'opencosmos-ui';
import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { updateOrganisation } from '_api/organisations/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { updateOrganisationPaymentType } from '_api/administration/service';

interface IProps {
  organisation?: Organisation;
  organisationCustomer?: OrganisationCustomer;
  disableBilling: boolean;
  refetchOrgCustomer: () => void;
  refetchOrganisation: () => void;
  disablePaymentChange: boolean;
}

const AdministrationOrganisationDetails = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isSubmittingChangePaymentFlowType,
    setIsSubmittingChangePaymentFlowType,
  ] = useState<boolean>(false);
  const { translate } = useLocalisation();

  const handleManageBillingClick = async () => {
    if (!props.organisation) {
      return;
    }
    setLoading(true);
    const { data } = await getBillingPortalURL({
      params: {
        organisationId: props?.organisation.id.toString(),
      },
    });
    if (data?.portal_link) {
      window.open(data?.portal_link, '_blank');
    }
    setLoading(false);
  };

  const paymentFlowType =
    props?.organisationCustomer?.payment_type ?? 'PAY_AS_YOU_GO';

  const paymentFlowTypeToUpdate =
    paymentFlowType === 'PAY_AS_YOU_GO' ? 'MONTHLY_PAYMENTS' : 'PAY_AS_YOU_GO';

  const handleChangeOrganisationPaymentFlowType = async () => {
    if (!props.organisation) {
      return;
    }
    setIsSubmittingChangePaymentFlowType(true);
    const { success } = await updateOrganisationPaymentType({
      params: {
        orgId: props?.organisation.id.toString(),
      },
      body: {
        payment_type: paymentFlowTypeToUpdate,
      },
    });
    if (success) {
      props?.refetchOrgCustomer();
    }
    setIsSubmittingChangePaymentFlowType(false);
  };

  return (
    <div>
      <h2 className="text-lg mt-6">{translate('organisationsPage.details')}</h2>
      <div className="pl-2">
        <h3 className="font-bold mt-4">
          {translate('organisationsPage.discoverability')}
        </h3>
        <Select
          className="mt-2 w-full"
          label={translate('organisationsPage.joinPolicy')}
          description={translate('organisationsPage.joinPolicyDescription')}
          selectedKey={props.organisation?.join_policy}
          fill
          placeholder="Select"
          onSelectionChange={async (policy) => {
            const res = await updateOrganisation({
              params: {
                id: props.organisation?.id.toString()!,
              },
              body: {
                join_policy: policy as unknown as OrganisationJoinPolicy,
              },
            });

            if (res.status === 200) {
              props.refetchOrganisation();
            }
          }}
        >
          <ListBoxItem id="INVITE">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.onInvite'
            )}
          </ListBoxItem>
          <ListBoxItem id="REQUEST">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.requestToJoin'
            )}
          </ListBoxItem>
          <ListBoxItem id="JOIN">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.join'
            )}
          </ListBoxItem>
        </Select>
        <TextField
          className="mt-2"
          label={translate('organisationsPage.domain')}
          value={props.organisation?.domain}
          isDisabled
        >
          <Input type="text" />
        </TextField>

        {!props.disableBilling && (
          <>
            <h3 className="font-bold mt-4">
              {translate('organisationsPage.billing')}
            </h3>
            <p className="text-sm">
              {translate('organisationsPage.billingDescription')}
            </p>
          </>
        )}
        {!props.disableBilling && (
          <Button
            intent="secondary"
            icon="DollarSign"
            iconPlacement="left"
            size="sm"
            className="mt-2 mr-4"
            onPress={handleManageBillingClick}
            isDisabled={loading}
          >
            {translate('organisationsPage.goToBilling')}
            {loading && <Spinner size={'sm'} />}
          </Button>
        )}

        {!props.disablePaymentChange && (
          <>
            <h3 className="font-bold mt-4">Payment flow type</h3>
            <p className="text-sm">
              Current payment flow type is{' '}
              {paymentFlowType?.replaceAll('_', ' ')}
            </p>
          </>
        )}

        {!props.disablePaymentChange && (
          <Button
            intent="secondary"
            icon="DollarSign"
            iconPlacement="left"
            size="sm"
            className="mt-2"
            onPress={handleChangeOrganisationPaymentFlowType}
            isDisabled={isSubmittingChangePaymentFlowType}
          >
            Switch payment flow type to{' '}
            <span className="font-bold">
              {paymentFlowTypeToUpdate?.replaceAll('_', ' ')?.toLowerCase()}
            </span>
            {isSubmittingChangePaymentFlowType && <Spinner size={'sm'} />}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdministrationOrganisationDetails;
