import { ILayerOptions, LayerSourceType } from './layer';
import { Layer } from './layer';

export type VectorTileLayerOptions = ILayerOptions & {
  vectorTileLayerStyles?: {
    [key: string]: {
      weight?: number;
      color?: string;
      fillColor?: string;
      fillOpacity?: number;
      radius?: number;
      fill?: boolean;
    };
  };
  interactive?: boolean;
};

export class VectorTileLayer extends Layer {
  declare sourceType: LayerSourceType;
  declare options: VectorTileLayerOptions;
  declare layerClass: string;
  declare name: string;
  declare position: number[];
  declare description: string;
  declare url: string;

  constructor(
    url: string,
    name: string,
    position: number[],
    options?: Partial<VectorTileLayerOptions>
  ) {
    super(LayerSourceType.VECTOR_TILE);
    this.url = url;
    this.name = name;
    this.position = position;
    this.layerClass = 'VectorTileLayer';

    this.options = {
      ...this.options,
      ...options,
      vectorTileLayerStyles: {
        default: {
          weight: 1,
          color: '#ff0000',
          fillColor: '#ff0000',
          fillOpacity: 1,
          radius: 2,
          fill: true,
        },
      },
    };
  }

  getName(): string {
    return this.name;
  }

  getDescription(): string | null {
    return '';
  }

  containsSTACItem(): boolean {
    return false;
  }
  containsSTACAsset(): boolean {
    return false;
  }
  containsBandAlgebra(): boolean {
    return false;
  }
  sza(): Number | undefined {
    return undefined;
  }
  href(): string | undefined {
    return undefined;
  }
  cloneWithOptions(options: Partial<VectorTileLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }
}

export const exampleVectorTileLayer = {
  url: 'https://test.app.open-cosmos.com/api/data/v0/vector-tiler/maps/smog/{z}/{x}/{y}.pbf',
  name: 'Test Vector Tile Layer',
  position: [0, 0],
  options: {
    interactive: true,
    visible: true,
    opacity: 1,
    blendMode: '',
  },
  sourceType: LayerSourceType.VECTOR_TILE,
  layerClass: 'VectorTileLayer',
  description: 'This is a test vector tile layer for testing purposes',
};
